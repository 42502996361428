@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Nunito:200,300,regular,500,600,700,800,900,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

html {
   font-family: "nunito", sans-serif;
}

._box_shadow { 
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

p {
   @apply text-_dark;
}

.spacing {
   padding-left: 32px;
   padding-right: 32px;
}

._shadow {
   box-shadow: 0px -3px 10px rgba(169, 169, 169, 0.16);
}

._toggle_bg {
   background: linear-gradient(
         0deg,
         rgba(255, 255, 255, 0.16),
         rgba(255, 255, 255, 0.16)
      ),
      #1c2f3b;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scroll::-webkit-scrollbar {
   display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scroll {
   -ms-overflow-style: none; /* IE and Edge */
   scrollbar-width: none; /* Firefox */
}


/* Chrome, Safari, Edge, Opera */
.numberHide::-webkit-outer-spin-button,
.numberHide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberHide[type=number] {
  -moz-appearance: textfield;
}